import React from 'react';

type AnyFunction = (...args: unknown[]) => void;

export function useEvent<T extends AnyFunction>(fn: T) {
  const ref = React.useRef<T>();

  React.useInsertionEffect(() => {
    ref.current = fn;
  }, [fn]);

  return React.useCallback((...args: Parameters<T>) => ref.current?.(...args), []);
}
