import cx from 'classnames';
import React from 'react';

import styles from './Button.module.css';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  accent?: boolean;
  buttonLink?: boolean;
}

export function Button({ className, children, type = 'button', accent = false, buttonLink = false, ...buttonProps }: Props) {
  const classes = buttonLink
    ? styles.link
    : cx(styles.button, className, { [styles.accent]: accent });

  return (
    <button type={type} className={classes} {...buttonProps}>
      {children}
    </button>
  );
}
