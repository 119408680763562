import cx from 'classnames';
import React from 'react';
import { Link as RouterLink } from 'wouter';

import { isDefined } from '../../utils/isDefined';

import styles from './Link.module.css';

type LinkVariant = 'secondaryAction' | 'info';

type Props = {
  external?: boolean;
  to: string;
  variant?: LinkVariant;
  children: React.ReactNode;
  target?: React.HTMLProps<HTMLLinkElement>['target'];
};

const VARIANT_STYLES: Record<LinkVariant, string> = {
  info: styles.info,
  secondaryAction: styles.secondaryAction,
};

export const Link = (props: Props) => {
  const { children, external = false, to, variant, target } = props;

  const classNames = cx(styles.link, isDefined(variant) && VARIANT_STYLES[variant]);

  if (external) {
    return (
      <a className={classNames} href={to} target={target}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink className={classNames} to={to}>
      {children}
    </RouterLink>
  );
};
