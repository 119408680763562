import cx from 'classnames';
import React from 'react';

import { isEmpty } from '../../utils/isEmpty';

import styles from './Notification.module.css';

type Type = 'error' | 'information';

interface Props {
  type: Type;
  title?: string;
  messages: string[];
}

const TYPE_STYLES: Record<Type, string> = {
  error: styles.error,
  information: styles.information,
};

const TITLES: Record<Type, string> = {
  error: 'Error',
  information: 'Information',
};

export const Notification = ({ type, title = TITLES[type], messages }: Props) => {
  if (isEmpty(messages)) {
    return null;
  }

  return (
    <div className={cx(styles.notification, TYPE_STYLES[type])}>
      <h3>{title}</h3>
      {messages.map((message, index) => (
        <div key={`${index}-${message}`}>{message}</div>
      ))}
    </div>
  );
};
