import React from 'react';

import { MFAFinalizeResponse } from '../../../typings/types';

import { EnableMFA } from './EnableMFA';
import { MFASuccess } from './MFASuccess';
import { SaveRecoveryCodes } from './SaveRecoveryCodes';
import { SecureYourAccount } from './SecureYourAccount';

const steps = ['ask', 'enable', 'save_codes', 'done'] as const;
type Step = typeof steps[number];

const getNextStep = (prevStep: Step): Step => {
  const index = steps.indexOf(prevStep);
  const nextIndex = Math.min(index + 1, steps.length - 1);

  return steps[nextIndex];
};

interface Props {
  redirectUri?: string;
  accessToken: string | undefined;
}

export const MFASetup = ({ redirectUri: initialRedirectUri, accessToken }: Props) => {
  const [step, setStep] = React.useState<Step>('ask');
  const [recoveryCodes, setRecoveryCodes] = React.useState<string[]>([]);
  const [redirectUri, setRedirectUri] = React.useState(initialRedirectUri);

  const handleContinue = React.useCallback(() => setStep(getNextStep), []);

  const handleEnable = React.useCallback((payload: MFAFinalizeResponse['data']) => {
    setRedirectUri(payload.redirect_uri);
    setRecoveryCodes(payload.recovery_codes);
    handleContinue();
  }, [handleContinue]);

  switch (step) {
    case 'ask':
      return <SecureYourAccount onContinue={handleContinue} url={redirectUri} />;
    case 'enable':
      return <EnableMFA onEnable={handleEnable} accessToken={accessToken} skipUrl={redirectUri} />;
    case 'save_codes':
      return <SaveRecoveryCodes codes={recoveryCodes} onContinue={handleContinue} />;
    case 'done':
      return <MFASuccess url={redirectUri} />;
    default:
      throw Error('Not possible');
  }
};
