import cx from 'classnames';
import React from 'react';

import { isDefined } from '../../utils/isDefined';
import { isEmpty } from '../../utils/isEmpty';
import { Link } from '../Link/Link';
import { Eye } from '../Logos/Eye';

import styles from './Input.module.css';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  link?: string;
  linkTitle?: string;
  description?: string;
}

export const Input: React.FC<Props> = ({
  type,
  autoComplete = 'off',
  className,
  label,
  children,
  link,
  linkTitle,
  description,
  value,
  ...inputProps
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const togglePasswordVisibility = () => setIsPasswordVisible(wasVisible => !wasVisible);

  const isPasswordInput = type === 'password';
  const inputType = isPasswordInput && isPasswordVisible
    ? 'text'
    : type;

  return (
    <label className={styles.container}>
      <div className={styles.labelContainer}>
        <span>{label}</span>
        {
          isDefined(link) && (
            <Link to={link}>
              {linkTitle}
            </Link>
          )
        }
      </div>
      {isDefined(description) && (
        <div className={styles.description}>{description}</div>
      )}
      <div className={styles.inputContainer}>
        <input
          type={inputType}
          value={value}
          autoComplete={autoComplete}
          className={cx(styles.input, className)}
          {...inputProps}
        />
        {isPasswordInput && (
          <button
            type="button"
            aria-label="Toggle password visibility"
            className={cx(styles.inputButton, { [styles.hidden]: isEmpty(String(value ?? '')) })}
            onClick={togglePasswordVisibility}
          >
            <Eye isOpen={!isPasswordVisible} />
          </button>
        )}
      </div>
    </label>
  );
};
