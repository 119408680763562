import makeMatcher from 'wouter/matcher';

export const routeMatcher = makeMatcher();

export const PATHS = {
  ACTIVATE: '/activate/:hash',
  CREATE: '/create',
  FAILED: '/failed',
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/',
  RESET_PASSWORD: '/forgot-password/:hash',
};
