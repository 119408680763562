/* eslint-disable no-console */
import {
  ActivateEmailPayload,
  ExternalSignInPayload,
  ExternalSignInResponse,
  LoginResponse,
  MFAFinalizeResponse,
  MFAInitializeResponse,
  MFAResetResponse,
  MFAVerifyResponse,
  ResetPasswordPayload,
} from '../../typings/types';
import { isDefined } from '../utils/isDefined';
import { authParams } from '../utils/params';

import { getClient } from './client';

const PATHS = {
  ACTIVATE_EMAIL: 'api/activate/',
  EXTERNAL_SIGNIN: 'authorize/external/',
  FORGOT_PASSWORD: 'api/forgot-password/',
  LOGIN: 'authorize/',
  MFA_FINALIZE: 'api/mfa/setup-finalize/',
  MFA_INITIALIZE: 'api/mfa/setup-initialize/',
  MFA_RESET: 'api/mfa/verify-code/reset',
  MFA_VERIFY: 'api/mfa/verify-code/',
};

type AuthHeader = { Authorization: string } | Record<string, never>;

const getAuthorizationHeader = (token?: string): AuthHeader => !isDefined(token)
  ? {}
  : { Authorization: `Bearer ${token}` };

const getErrors = (error: Unrestricted) => {
  return { errors: [error.error ?? 'Something went wrong.'] as string[] };
};

const client = getClient(import.meta.env.CENTRA_API_URL);

export async function login(payload: { email: string; password: string }) {
  try {
    const { data } = await client<LoginResponse>(PATHS.LOGIN, payload, { method: 'POST', params: authParams });

    return data;
  } catch (error) {
    console.error('Could not authorize the user. Error message:', error.message);

    return getErrors(error);
  }
}

export async function externalSignIn(payload: ExternalSignInPayload) {
  try {
    const { data } = await client<ExternalSignInResponse>(PATHS.EXTERNAL_SIGNIN, payload, { method: 'POST', params: authParams });

    return data;
  } catch (error) {
    console.error('Could not authorize the user. Error message:', error.message);

    return getErrors(error);
  }
}

export async function postForgotPassword(email: string) {
  try {
    await client(PATHS.FORGOT_PASSWORD, { email }, { method: 'POST' });
  } catch (error) {
    return getErrors(error);
  }
}

export async function checkResetPasswordHash(hash: string) {
  try {
    await client(`${PATHS.FORGOT_PASSWORD}${hash}`);
  } catch (error) {
    return getErrors(error);
  }
}

export async function checkActivateEmailHash(hash: string) {
  try {
    await client(`${PATHS.ACTIVATE_EMAIL}${hash}`);
  } catch (error) {
    return getErrors(error);
  }
}

export async function resetPassword({ password, repeatPassword, hash, code }: ResetPasswordPayload) {
  try {
    await client(
      PATHS.FORGOT_PASSWORD,
      {
        hash,
        pass: password,
        repass: repeatPassword,
        ...(isDefined(code) ? { code } : {}),
      },
      { method: 'PATCH' }
    );
  } catch (error) {
    if (!isDefined(code) && error.data?.redirectToVerificationPage === true) {
      return { needsMFACode: true };
    }

    return getErrors(error);
  }
}

export async function activateEmail({ password, repeatPassword, hash }: ActivateEmailPayload) {
  try {
    await client(
      PATHS.ACTIVATE_EMAIL,
      {
        hash,
        pass: password,
        repass: repeatPassword,
      },
      { method: 'PATCH' }
    );
  } catch (error) {
    return getErrors(error);
  }
}

export async function initializeMFA(accessToken?: string) {
  try {
    const authHeader = getAuthorizationHeader(accessToken);

    return await client<MFAInitializeResponse>(PATHS.MFA_INITIALIZE, undefined, { headers: authHeader, method: 'POST' });
  } catch (error) {
    return getErrors(error);
  }
}

export async function finalizeMFA(
  payload: {code: string},
  accessToken?: string,
) {
  try {
    const authHeader = getAuthorizationHeader(accessToken);
    const { data } = await client<MFAFinalizeResponse>(PATHS.MFA_FINALIZE, payload, {
      headers: authHeader,
      method: 'POST',
      params: authParams,
    });

    return data;
  } catch (error) {
    return getErrors(error);
  }
}

export async function verifyMFA(
  payload: {code: string},
  accessToken?: string,
) {
  try {
    const authHeader = getAuthorizationHeader(accessToken);
    const { data } = await client<MFAVerifyResponse>(PATHS.MFA_VERIFY, payload, {
      headers: authHeader,
      method: 'POST',
      params: authParams,
    });

    return data;
  } catch (error) {
    return getErrors(error);
  }
}

export async function resetMFA(
  payload: {code: string},
  accessToken?: string,
) {
  try {
    const authHeader = getAuthorizationHeader(accessToken);
    const { data } = await client<MFAResetResponse>(PATHS.MFA_RESET, payload, {
      headers: authHeader,
      method: 'POST',
      params: authParams,
    });

    return data;
  } catch (error) {
    return getErrors(error);
  }
}
