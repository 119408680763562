import { createSchema, required, sameAs } from './validation';

export const MIN_PASSWORD_LENGTH = 8;
export const MIN_NUMBERS_COUNT = 1;
export const MIN_SYMBOLS_COUNT = 1;
export const MIN_UPPERCASE_COUNT = 1;

const COUNT_REQUIREMENTS = [
  { minCount: MIN_SYMBOLS_COUNT, regex: /[^a-zA-Z0-9\s]/g },
  { minCount: MIN_NUMBERS_COUNT, regex: /[0-9]/g },
  { minCount: MIN_UPPERCASE_COUNT, regex: /[A-Z]/g },
];

const requirementsValidator = (message: string) => (password: string) => {
  const areRequirementsOk = password.length >= MIN_PASSWORD_LENGTH
    && COUNT_REQUIREMENTS.every(({ minCount, regex }) => (password.match(regex) ?? []).length >= minCount);

  return areRequirementsOk
    ? null
    : message;
};

export const passwordSchema = createSchema({
  password: [
    required('Password is required.'),
    requirementsValidator('Your new password does not meet all requirements.'),
  ],
  repeatPassword: [sameAs('Passwords do not match.', { key: 'password' })],
});
