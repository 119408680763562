import React from 'react';

import { activateEmail, checkActivateEmailHash } from '../../api/api';
import {
  Button,
  Errors,
  Form,
  Input,
  Notification,
  Title,
} from '../../components';
import { PasswordRules } from '../../components/PasswordRules/PasswordRules';
import { isDefined } from '../../utils/isDefined';
import { passwordSchema } from '../../utils/passwordSchema';
import { PATHS, routeMatcher } from '../../utils/routes';

import styles from './ActivateEmail.module.css';

interface Props {
  hash: string;
}

const [isActivatePage, params] = routeMatcher(PATHS.ACTIVATE, location.pathname);

if (isActivatePage && isDefined(params.hash)) {
  checkActivateEmailHash(params.hash);
}

// eslint-disable-next-line max-len
const INFORMATION_MESSAGE = 'Before activating your email, please make sure that you have created Centra user linked to your email. If not, reach out to your point of contact with Centra.';

export const ActivateEmail = ({ hash }: Props) => {
  const [password, setPassword] = React.useState('');
  const [repeatPassword, setRepeatPassword] = React.useState('');
  const [errors, setErrors] = React.useState<string[]>([]);
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const formId = React.useId();

  const handlePassChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(evt.target.value);
    setErrors([]);
  }, []);

  const handleRepeatPassChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(evt.target.value);
    setErrors([]);
  }, []);

  const setPass = React.useCallback(async () => {
    const validation = passwordSchema.validate({ password, repeatPassword });
    if (!validation.isSuccess) {
      setErrors(validation.messages);

      return;
    }

    const response = await activateEmail({ hash, password, repeatPassword });

    if (isDefined(response) && 'errors' in response) {
      setErrors(response.errors);

      return;
    }

    setIsSuccessful(true);
  }, [password, repeatPassword, hash]);

  const handleSubmit = React.useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    setPass();
  }, [setPass]);

  if (isSuccessful) {
    return (
      <>
        <Title>Email is activated</Title>
        <p>Thank you! Your email has been successfully activated. Now you can log in using your new password.</p>
      </>
    );
  }

  return (
    <>
      <Title>Activate your email</Title>
      <Form id={formId} onSubmit={handleSubmit}>
        <div className={styles.informationContainer}>
          <Notification type="information" messages={[INFORMATION_MESSAGE]} />
        </div>
        <Input
          type="password"
          label="New password"
          placeholder="New password"
          onChange={handlePassChange}
          value={password}
          required
        />
        <Input
          type="password"
          label="Confirm new password"
          placeholder="Confirm new password"
          onChange={handleRepeatPassChange}
          value={repeatPassword}
          required
        />
        <Errors messages={errors} />
        <PasswordRules />
      </Form>
      <Button type="submit" form={formId} accent>Activate</Button>
    </>
  );
};
