import { AuthConfig, PopupArgs } from './types';

const SIZE_GAP = 40;
const DEFAULT_POPUP_WIDTH = 500;
const DEFAULT_POPUP_HEIGHT = 550;
export const STATE_PREFIX = 'auth-hook_';

export const getStateKey = (state: string) => `auth/${state}`;

export const createPopup = ({ url, title = '', height = DEFAULT_POPUP_HEIGHT, width = DEFAULT_POPUP_WIDTH }: PopupArgs) => {
  const popupWidth = Math.min(width, screen.width - SIZE_GAP);
  const popupHeight = Math.min(height, screen.height - SIZE_GAP);

  const left = (screen.width - popupWidth) / 2;
  const top = (screen.height - popupHeight) / 2;

  const popup = window.open(url, title, `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`);
  popup?.focus();

  return popup;
};

type AuthParams = AuthConfig & {state: string};

export const getAuthHref = ({ provider, clientId, redirectUri = location.origin, scope, extraParams, state }: AuthParams) => {
  const url = new URL(provider);
  const params = url.searchParams;
  const paramsToSet = {
    ...extraParams,
    'client_id': clientId,
    'redirect_uri': redirectUri,
    'response_type': 'code',
    'scope': scope.join(' '),
    state,
  };

  Object.entries(paramsToSet).forEach(([key, value]) => params.set(key, value));

  return url.href;
};
