export function isEmpty<T extends string | Unrestricted[] | Record<string, Unrestricted>>(
  subject: T | Empty.Array | Empty.Object | Empty.String): subject is EmptyStringArrayOrObject<T> {
  switch (typeof subject) {
    case 'object':
      return (Object.keys(subject).length === 0);
    case 'string':
      return (subject === '');
    default:
      return false;
  }
}

type EmptyStringArrayOrObject<T> =
  T extends string
    ? Empty.String
    : T extends Unrestricted[]
        ? Empty.Array
        : T extends Record<string, Unrestricted>
            ? Empty.Object
            : never;
