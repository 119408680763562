import React from 'react';

import { BackToLogin, Errors, Title } from '../../components';
import { isDefined } from '../../utils/isDefined';

const SILK_ERROR_KEY = 'silk_error';

/* eslint-disable max-len */
const SILK_ERROR_MESSAGES: Record<string, string> = {
  access_forbidden: 'Access forbidden due to permission denial.',
  access_restricted_disabled_mfa: 'Your account has been deactivated due to the reset of Multi-Factor Authentication. To reactivate it, please reach out to your point of contact with Centra.',
  code_expired: 'Your session has expired. Please start the process again.',
};
/* eslint-enable max-len */

const getSilkError = () => {
  const { searchParams } = new URL(window.location.href);
  const error = searchParams.get(SILK_ERROR_KEY);

  if (!isDefined(error)) {
    return;
  }

  return SILK_ERROR_MESSAGES[error];
};

export const OperationFailed = () => {
  const message = history.state?.message ?? getSilkError() ?? 'Something went wrong.';

  return (
    <>
      <Title>Operation failed</Title>
      <Errors messages={[message]} />
      <BackToLogin />
    </>
  );
};
