import React from 'react';

import styles from './LoadingSpinner.module.css';

interface Props {
  width?: number;
  colorGradient?: readonly [string, string, string];
}

const DEFAULT_WIDTH = 44;
const DEFAULT_COLOR_GRADIENT = ['#F5F5F5', '#A0B1E6', '#0037E5'] as const;

export const LoadingSpinner = ({ width = DEFAULT_WIDTH, colorGradient = DEFAULT_COLOR_GRADIENT }: Props) => (
  <svg width={width} height={width} className={styles.spinner} viewBox="0 0 44 44" fill="none">
    <defs>
      <linearGradient id="spinner-firstHalf">
        <stop offset="0%" stopColor={colorGradient[2]} />
        <stop offset="100%" stopColor={colorGradient[1]} />
      </linearGradient>
      <linearGradient id="spinner-secondHalf">
        <stop offset="0%" stopColor={colorGradient[0]} />
        <stop offset="100%" stopColor={colorGradient[1]} />
      </linearGradient>
    </defs>

    <g strokeWidth="5">
      <path stroke="url(#spinner-secondHalf)" d="M 4 22 A 18 18 0 0 1 40 22" />
      <path stroke="url(#spinner-firstHalf)" d="M 40 22 A 18 18 0 0 1 4 22" />
    </g>
  </svg>
);
