import React from 'react';

import { MIN_NUMBERS_COUNT, MIN_PASSWORD_LENGTH, MIN_SYMBOLS_COUNT, MIN_UPPERCASE_COUNT } from '../../utils/passwordSchema';
import { pluralize } from '../../utils/pluralize';

import styles from './PasswordRules.module.css';

export const PasswordRules = () => {
  return (
    <div className={styles.rulesContainer}>
      Password must contain minimum:
      <ul>
        <li>{MIN_PASSWORD_LENGTH} {pluralize('character', MIN_PASSWORD_LENGTH)}, </li>
        <li>{MIN_SYMBOLS_COUNT} {pluralize('special character', MIN_SYMBOLS_COUNT)}, </li>
        <li>{MIN_UPPERCASE_COUNT} {pluralize('upper case character', MIN_UPPERCASE_COUNT)}, </li>
        <li>{MIN_NUMBERS_COUNT} {pluralize('number', MIN_NUMBERS_COUNT)}.</li>
      </ul>
    </div>
  );
};
