import React from 'react';

import { externalSignIn } from '../../api/api';
import { useAuth } from '../../hooks/useAuth';
import { AuthFailureResponse, AuthSuccessResponse } from '../../hooks/useAuth/types';
import { pushLocation } from '../../utils/pushLocation';
import { Button } from '../Button/Button';
import { GoogleLogo } from '../Logos';

const GOOGLE_AUTH_CONFIG = {
  clientId: import.meta.env.CENTRA_GOOGLE_CLIENT_ID,
  extraParams: {
    'enable_serial_consent': true,
    'include_granted_scopes': true,
    'prompt': 'select_account',
  },
  height: 550,
  provider: 'https://accounts.google.com/o/oauth2/v2/auth',
  scope: ['openid', 'profile', 'email'],
  width: 500,
};

interface Props {
  setErrors: (errors: string[]) => void;
}

export const GoogleButton = ({ setErrors }: Props) => {
  const onSuccess = async ({ code }: AuthSuccessResponse) => {
    try {
      const data = await externalSignIn({ code, provider: 'google' });
      if ('errors' in data) {
        setErrors(data.errors);

        return;
      }

      pushLocation(data.redirect_uri);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      setErrors([error.error_description ?? 'Something went wrong.']);
    }
  };

  const onFailure = (errors: AuthFailureResponse) => {
    setErrors(['Something went wrong.']);
    console.error(errors); // eslint-disable-line no-console
  };

  const login = useAuth({ config: GOOGLE_AUTH_CONFIG, onFailure, onSuccess });

  return (
    <Button onClick={login}>
      <GoogleLogo /> Google
    </Button>
  );
};
