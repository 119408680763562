import React from 'react';

import { ActionContainer, Button, Errors, Form, Input, Title } from '../../components';
import { isDefined } from '../../utils/isDefined';
import { isEmpty } from '../../utils/isEmpty';

interface Props {
  onConfirm: (code: string) => void;
  onReset?: () => void;
  title: string;
  errors: string[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  submitText?: string;
}

export const MFAVerify = ({ onConfirm, title, errors, setErrors, onReset, submitText = 'Confirm' }: Props) => {
  const [code, setCode] = React.useState('');
  const formId = React.useId();

  const handleCodeChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setCode(evt.target.value);
    setErrors([]);
  }, [setErrors]);

  const handleVerify = React.useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();

    if (isEmpty(code)) {
      setErrors(['Verification code is required.']);

      return;
    }

    onConfirm(code);
  }, [code, onConfirm, setErrors]);

  return (
    <>
      <Title>{title}</Title>
      <Form id={formId} onSubmit={handleVerify}>
        <Input
          label="Enter verification code from an app"
          placeholder="Verification code"
          onChange={handleCodeChange}
          value={code}
          required
        />
        <Errors messages={errors} />
      </Form>
      <ActionContainer>
        <Button type="submit" form={formId} accent>{submitText}</Button>
        {isDefined(onReset) && <Button buttonLink onClick={onReset}>Lost your device?</Button>}
      </ActionContainer>
    </>
  );
};
