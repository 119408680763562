import React from 'react';

import { Button, Title } from '../../components';
import { isDefined } from '../../utils/isDefined';
import { pushLocation } from '../../utils/pushLocation';

interface Props {
  url?: string;
}

export const MFASuccess = ({ url }: Props) => {
  const handleProceed = React.useCallback(() => pushLocation(url ?? ''), [url]);

  return (
    <>
      <Title>Enabling MFA completed!</Title>
      <p>
        Now your account is better protected. Going forward you will need an email, password and verification code from an app to log in.
      </p>
      {isDefined(url) && <Button onClick={handleProceed} accent>Click here to proceed</Button>}
    </>
  );
};
