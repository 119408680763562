import { getQueryParams } from './getQueryParamsFromQueryString';
import { isDefined } from './isDefined';
import { pick } from './pick';

const AUTH_PARAM_KEYS = ['client_id', 'redirect_uri', 'response_type', 'state'] as const;

type Params = Partial<Record<typeof AUTH_PARAM_KEYS[number], string>>;

const allParams: Params = getQueryParams(window.location.search);

export const authParams = pick(allParams, AUTH_PARAM_KEYS);
export const hasValidParams = AUTH_PARAM_KEYS.every(key => key in authParams);

export const getLinkWithParams = (link: string) => {
  const url = new URL(link, window.location.origin);
  const urlParams = url.searchParams;
  const missingKeys = AUTH_PARAM_KEYS.filter(key => !urlParams.has(key));

  missingKeys.forEach(key => {
    const value = authParams[key];
    if (isDefined(value)) {
      urlParams.set(key, value);
    }
  });

  return url.href;
};
