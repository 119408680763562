import React from 'react';

import { ActionContainer, Button, Title } from '../../components';

import styles from './MFASetup.module.css';

interface Props {
  codes: Id[];
  onContinue: () => void;
}

export const SaveRecoveryCodes = ({ codes = [], onContinue }: Props) => {
  const downloadCodes = React.useCallback(() => {
    const contentBlob = new Blob([codes.join('\r\n')], { type: 'text/plain' });
    const downloadUrl = window.URL.createObjectURL(contentBlob);
    const anchor = document.createElement('a');
    // eslint-disable-next-line functional/immutable-data
    Object.assign(anchor, {
      download: 'centra_recovery_codes.txt',
      href: downloadUrl,
    });
    anchor.click();
    window.URL.revokeObjectURL(downloadUrl);
  }, [codes]);

  return (
    <>
      <Title>Save your recovery codes</Title>
      <p>
        Click Download to save recovery codes to your device.
        Recovery codes can help you get back into your account if you lose access.
        After saving codes click Continue.
        <ul className={styles.codeList}>
          {codes.map((code, index) => <li key={index}>{code}</li>)}
        </ul>
      </p>
      <ActionContainer>
        <Button onClick={downloadCodes} accent>Download</Button>
        <Button onClick={onContinue}>Continue</Button>
      </ActionContainer>
    </>
  );
};
