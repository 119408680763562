/* eslint-disable max-len */
import React from 'react';

interface Props {
  width?: number;
}

const DEFAULT_WIDTH = 17;

export const CentraShort = ({ width = DEFAULT_WIDTH }: Props) => (
  <svg width={width} viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.58329 18.5774C4.01 18.5774 5.16657 17.4194 5.16657 15.9909C5.16657 14.5623 4.01 13.4043 2.58329 13.4043C1.15658 13.4043 0 14.5623 0 15.9909C0 17.4194 1.15658 18.5774 2.58329 18.5774Z" fill="black" />
    <path d="M11.1302 11.4903C12.8444 11.4903 14.1611 10.3462 14.6331 9.20219L16.869 10.6447C15.7262 12.6592 13.6891 14.0769 10.9812 14.0769C7.37891 14.0769 4.09961 11.2167 4.09961 7.03843C4.09961 2.88501 7.37891 0 10.9812 0C13.7139 0 15.7262 1.44251 16.8441 3.45704L14.6083 4.89954C14.1611 3.75549 12.8692 2.61143 11.1302 2.61143C8.39747 2.61143 6.90689 4.62597 6.90689 7.0633C6.90689 9.50064 8.39747 11.4903 11.1302 11.4903Z" fill="black" />
  </svg>
);
