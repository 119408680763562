/* eslint-disable max-len */
import React from 'react';

interface Props {
  width?: number;
}

const DEFAULT_WIDTH = 59;
const DEFAULT_HEIGHT = 28;
const WIDTH_HEIGHT_RATIO = DEFAULT_HEIGHT / DEFAULT_WIDTH;

export const Smile = ({ width = DEFAULT_WIDTH }: Props) => (
  <svg width={width} height="28" viewBox="0 0 59 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M29.4201 28C13.5908 28 0.319783 15.52 0 0.16L9.27371 0C9.5935 10.4 18.8672 19.04 29.5799 19.04C40.2927 19.04 49.5664 10.24 49.8862 0L59.1599 0.16C58.5203 15.52 45.4092 28 29.4201 28Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={width} height={width * WIDTH_HEIGHT_RATIO} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
