import { isDefined } from './isDefined';
import { isEmpty } from './isEmpty';

export namespace Validator {
  export type ValidationResult = { isSuccess: true } | { isSuccess: false; messages: string[] };
  export type ValidatorFn = (str: string, obj: Input) => string | null;
  export type Schema = Record<string, ValidatorFn[]>;
  export type Input = Record<string, string>;
}

const validateAll = (validators: Validator.ValidatorFn[], str: string, input: Validator.Input) => {
  for (const validator of validators) {
    const message = validator(str, input);
    if (isDefined(message)) {
      return message;
    }
  }

  return null;
};

export const createSchema = (schema: Validator.Schema) => ({
  isValid(input: Validator.Input) {
    return Object.entries(schema)
      .every(([key, validators]) => !isDefined(validateAll(validators, input[key], input)));
  },
  validate(input: Validator.Input): Validator.ValidationResult {
    const messages = Object.entries(schema)
      .map(([key, validators]) => validateAll(validators, input[key], input))
      .filter(isDefined);

    if (isEmpty(messages)) {
      return { isSuccess: true };
    }

    return { isSuccess: false, messages };
  },
});

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; // W3C spec

export const required = (message: string = '') => (str?: string) =>
  (!isDefined(str) || isEmpty(str)) ? message : null;

export const isEmail = (message: string = '') => (str: string) =>
  EMAIL_REGEX.test(str) ? null : message;

export const sameAs = (message: string = '', { key }: { key: string }) =>
  (str: string, obj: Validator.Input) => str !== obj[key] ? message : null;
