import { ErrorResponse } from '../../typings/types';
import { isDefined } from '../utils/isDefined';
import { hideLoader, showLoader } from '../utils/loader';
import { PATHS } from '../utils/routes';

interface Config extends Omit<RequestInit, 'body'> {
  params?: Record<string, string>;
}

const getError = async (response: Response) => {
  const data = (await response.text()).trim();

  try {
    return JSON.parse(data);
  } catch {
    return { error: data };
  }
};

const checkForFailedRedirect = (response: ErrorResponse) => {
  if (response.data?.redirectToFailedPage) {
    history.pushState({ message: response.error }, '', PATHS.FAILED);
  }
};

export const getClient = (baseUrl: string) => async <T>(
  endpoint: string,
  body?: Record<string, Unrestricted>,
  { headers, params, ...options }: Config = {}
): Promise<T> => {
  const config = {
    ...options,
    ...isDefined(body) ? { body: JSON.stringify(body) } : {},
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const url = new URL(`${baseUrl}${endpoint}`);
  const { searchParams } = url;
  Object.entries(params ?? {}).forEach(([key, value]) => searchParams.set(key, value));
  showLoader();

  return window
    .fetch(url.href, config)
    .then(async response => {
      if (response.ok) {
        return await response.json();
      }

      const errorResponse = await getError(response);

      checkForFailedRedirect(errorResponse);

      throw errorResponse;
    })
    .finally(hideLoader);
};
