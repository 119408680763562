import React from 'react';

import { pluralize } from '../../utils/pluralize';
import { Notification } from '../Notification/Notification';

interface Props {
  messages: string[];
}

export const Errors = ({ messages }: Props) => {
  return <Notification
    type="error"
    title={pluralize('Error', messages.length)}
    messages={messages}
  />;
};
