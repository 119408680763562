import React from 'react';

import { ActionContainer, Button, Link, Title } from '../../components';
import { isDefined } from '../../utils/isDefined';

interface Props {
  onContinue: () => void;
  url?: string;
}

export const SecureYourAccount = ({ onContinue, url }: Props) => {
  return (
    <>
      <Title>Secure your account</Title>
      <p>
        Add an extra layer of security to your Centra account by enabling Multi-Factor Authentication (MFA).
        See {' '}
        <Link
          external
          variant="info"
          target="_blank"
          to="https://support.centra.com/centra-sections/settings/users/mfa"
        >
          this article
        </Link>
        {' '} for instructions on how to enable MFA.
        <br /><br />
        Once you've set up your authenticator app, click Continue for next steps.
      </p>
      <ActionContainer>
        <Button onClick={onContinue} accent>Continue</Button>
        {isDefined(url) && <Link variant="secondaryAction" external to={url}>Skip for now</Link>}
      </ActionContainer>
    </>
  );
};
