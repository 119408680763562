import React from 'react';

import { getLinkWithParams, hasValidParams } from '../../utils/params';
import { PATHS } from '../../utils/routes';
import { Link } from '../Link/Link';

export const BackToLogin = () => {
  return hasValidParams
    ? <Link variant="secondaryAction" to={getLinkWithParams(PATHS.LOGIN)}>Back to login</Link>
    : null;
};
