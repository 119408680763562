const LOADER_DELAY = 1750;
const LOADING_CLASS = 'loading';

class Loader {
  timeout: NodeJS.Timeout | undefined;
  loaderCount = 0;

  showLoader = () => {
    // eslint-disable-next-line functional/immutable-data
    this.loaderCount++;
    if (this.loaderCount === 1) {
      // eslint-disable-next-line functional/immutable-data
      this.timeout = setTimeout(() => document.body.classList.add(LOADING_CLASS), LOADER_DELAY);
    }
  };

  hideLoader = () => {
    // eslint-disable-next-line functional/immutable-data
    this.loaderCount = Math.max(0, this.loaderCount - 1);
    if (this.loaderCount === 0) {
      clearTimeout(this.timeout);
      document.body.classList.remove(LOADING_CLASS);
    }
  };
}

const loader = new Loader();

export const { hideLoader, showLoader } = loader;
