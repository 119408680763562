import React from 'react';
import { Route, Router, Switch } from 'wouter';

import { Container } from './components/';
import { PATHS, routeMatcher } from './utils/routes';
import { ActivateEmail } from './views/ActivateEmail/ActivateEmail';
import { CreateAccount } from './views/CreateAccount/CreateAccount';
import { ForgotPassword } from './views/ForgotPassword/ForgotPassword';
import { Login } from './views/Login/Login';
import { OperationFailed } from './views/OperationFailed/OperationFailed';
import { SetNewPassword } from './views/SetNewPassword/SetNewPassword';

export function Routes() {
  return (
    <Router matcher={routeMatcher}>
      <Container>
        <Switch>
          <Route path={PATHS.FAILED} component={OperationFailed} />
          <Route path={PATHS.ACTIVATE}>
            {(params: {hash: string}) => <ActivateEmail hash={params.hash} />}
          </Route>
          <Route path={PATHS.RESET_PASSWORD}>
            {(params: {hash: string}) => <SetNewPassword hash={params.hash} />}
          </Route>
          <Route path={PATHS.FORGOT_PASSWORD} component={ForgotPassword} />
          <Route path={PATHS.LOGIN} component={Login} />
          <Route path={PATHS.CREATE} component={CreateAccount} />
        </Switch>
      </Container>
    </Router>
  );
}
