import React from 'react';

import { login, verifyMFA } from '../../api/api';
import {
  Button,
  Errors,
  Form,
  Input,
  Link,
  Title,
} from '../../components';
import { GoogleButton } from '../../components/GoogleButton/GoogleButton';
import { LoadingSpinner } from '../../components/Logos';
import { MicrosoftButton } from '../../components/MicrosoftButton/MicrosoftButton';
import { useIsWaitingForAuth } from '../../hooks/useIsWaitingForAuth';
import { isDefined } from '../../utils/isDefined';
import { getLinkWithParams, hasValidParams } from '../../utils/params';
import { pushLocation } from '../../utils/pushLocation';
import { PATHS } from '../../utils/routes';
import { createSchema, isEmail, required } from '../../utils/validation';
import { MFAReset } from '../MFAReset/MFAReset';
import { MFASetup } from '../MFASetup/MFASetup';
import { MFAVerify } from '../MFAVerify/MFAVerify';

import styles from './Login.module.css';

const isGoogleSignInEnabled = isDefined(import.meta.env.CENTRA_GOOGLE_CLIENT_ID);
const isMicrosoftSignInEnabled = isDefined(import.meta.env.CENTRA_MICROSOFT_CLIENT_ID);

const formSchema = createSchema({
  email: [
    required('Email is required.'),
    isEmail('Email must be valid.'),
  ],
  password: [required('Password is required.')],
});

type MFA_VIEW = 'setup' | 'verify' | 'reset';

export const Login = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errors, setErrors] = React.useState<string[]>([]);
  const [mfaView, setMfaView] = React.useState<MFA_VIEW>();
  const [redirectUri, setRedirectUri] = React.useState<string>();
  const [accessToken, setAccessToken] = React.useState<string>();
  const forgotPasswordLink = getLinkWithParams(PATHS.FORGOT_PASSWORD);
  const isWaitingForAuth = useIsWaitingForAuth();

  const formId = React.useId();

  const handleEmailChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
    setErrors([]);
  }, []);

  const handlePassChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(evt.target.value);
    setErrors([]);
  }, []);

  const handleSubmit = React.useCallback(async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const formData = { email, password };
    const validation = formSchema.validate(formData);

    if (!validation.isSuccess) {
      setErrors(validation.messages);

      return;
    }

    const data = await login(formData);

    if ('errors' in data) {
      setErrors(data.errors);

      return;
    }

    const { redirect } = data;

    switch (redirect.type) {
      case 'external':
        pushLocation(redirect.redirect_uri);
        break;
      case 'mfa_verification':
        setAccessToken(redirect.access_token);
        setMfaView('verify');
        break;
      case 'mfa_setup':
        setAccessToken(redirect.access_token);
        setRedirectUri(redirect.redirect_uri);
        setMfaView('setup');
        break;
      case 'mfa_setup_mandatory':
        setAccessToken(redirect.access_token);
        setMfaView('setup');
        break;
      default:
    }
  }, [email, password]);

  const onVerifyMfa = React.useCallback((code: string) => {
    verifyMFA({ code }, accessToken).then(data => {
      if ('errors' in data) {
        setErrors(data.errors);

        return;
      }

      pushLocation(data.redirect_uri);
    });
  }, [accessToken]);

  const onReEnableMfa = React.useCallback((uri: string) => {
    setRedirectUri(uri);
    setMfaView('setup');
  }, []);

  if (isWaitingForAuth) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingSpinner />
      </div>
    );
  }

  if (!hasValidParams) {
    return <p>Missing valid url parameters. Please use valid url to log in.</p>;
  }

  if (mfaView === 'setup') {
    return <MFASetup redirectUri={redirectUri} accessToken={accessToken} />;
  }

  if (mfaView === 'verify') {
    return <MFAVerify
              title="2-step verification"
              onConfirm={onVerifyMfa}
              errors={errors}
              setErrors={setErrors}
              onReset={() => setMfaView('reset')}
            />;
  }

  if (mfaView === 'reset') {
    return <MFAReset accessToken={accessToken} handleSetup={onReEnableMfa} />;
  }

  return (
    <>
      <Title>Login to your Centra account.</Title>
      <Form onSubmit={handleSubmit} id={formId}>
        <Input
          type="email"
          label="Email"
          placeholder="name@example.com"
          onChange={handleEmailChange}
          value={email}
          required
        />
        <Input
          type="password"
          label="Password"
          placeholder="Password"
          onChange={handlePassChange}
          value={password}
          required
          link={forgotPasswordLink}
          linkTitle="Forgot password?"
        />
        <Errors messages={errors} />
      </Form>
      <div className={styles.buttonsContainer}>
        <Button type="submit" form={formId} accent>Log in</Button>
        {(isGoogleSignInEnabled || isMicrosoftSignInEnabled) && (
          <>
            <div className={styles.continueWith}>Or continue with</div>
            <div className={styles.providerButtonsWrapper}>
              { isGoogleSignInEnabled && <GoogleButton setErrors={setErrors} /> }
              { isMicrosoftSignInEnabled && <MicrosoftButton setErrors={setErrors} /> }
            </div>
          </>
        )}
        <div className={styles.createAccount}>
          <Link variant="secondaryAction" to={getLinkWithParams(PATHS.CREATE)}>
            Create an account
          </Link>
        </div>
      </div>
    </>
  );
};
