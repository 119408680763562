import cx from 'classnames';
import React from 'react';

import styles from './Title.module.css';

export const Title = (props: React.HTMLAttributes<HTMLHeadingElement>) => {
  const { className, children, ...titleProps } = props;

  return (
    <h2 className={cx(styles.title, className)} {...titleProps}>
      {children}
    </h2>
  );
};
