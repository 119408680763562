import React from 'react';

import { resetMFA } from '../../api/api';
import {
  ActionContainer,
  Button,
  Errors,
  Form,
  Input,
  Title,
} from '../../components';
import { isDefined } from '../../utils/isDefined';
import { isEmpty } from '../../utils/isEmpty';
import { pushLocation } from '../../utils/pushLocation';

interface Props {
  accessToken?: string;
  handleSetup: (redirectUri: string | undefined) => void;
}

export const MFAReset = ({ accessToken, handleSetup }: Props) => {
  const [code, setCode] = React.useState('');
  const [errors, setErrors] = React.useState<string[]>([]);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [redirectUri, setRedirectUri] = React.useState<string>();
  const formId = React.useId();
  const canSkipSetup = isDefined(redirectUri);

  const handleCodeChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setCode(evt.target.value);
    setErrors([]);
  }, []);

  const handleSubmit = React.useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isEmpty(code)) {
      setErrors(['Recovery code is required.']);

      return;
    }

    resetMFA({ code }, accessToken).then(data => {
      if ('errors' in data) {
        setErrors(data.errors);

        return;
      }
      setRedirectUri(data.redirect_uri ?? undefined);
      setIsSuccess(true);
    });
  }, [code, accessToken]);

  if (isSuccess) {
    return (
      <>
      <Title>Reset of Multi-Factor Authentication completed</Title>
      <p>
        {canSkipSetup
          ? 'Now you will no longer need a verification code to log in. Choose an option to continue with:'
          : 'In order to login again, you need to enable MFA with new device.'
        }
      </p>
      <ActionContainer>
        <Button onClick={() => handleSetup(redirectUri)} accent>Enable MFA with new device</Button>
        {canSkipSetup && (
          <Button onClick={() => pushLocation(redirectUri)}>Proceed without MFA</Button>
        )}
      </ActionContainer>
    </>
    );
  }

  return (
    <>
      <Title>Reset Multi-Factor Authentication</Title>
      <p>If you lost your device by entering the next steps you will be able to replace it with a new one or proceed without MFA.</p>
      <Form id={formId} onSubmit={handleSubmit}>
        <Input
          type="text"
          label="Use recovery code"
          placeholder="Recovery code"
          onChange={handleCodeChange}
          value={code}
          required
        />
        <Errors messages={errors} />
      </Form>
      <Button type="submit" form={formId} accent>Reset Multi-Factor Authentication</Button>
    </>
  );
};
