import { getStateKey, STATE_PREFIX } from './utils';

export const getIsWaitingForAuth = (search: string) => {
  const params = new URLSearchParams(search);
  const state = params.get('state');

  return state?.startsWith(STATE_PREFIX) ?? false;
};

/*
  This should only run on the popup window that is redirected from an auth provider.
  Immediately set and remove item in localStorage. The window that initiated oauth
  will be listening for this.
*/
export const dispatchAuthSuccess = (params: Record<string, string>) => {
  const stateKey = getStateKey(params.state);
  window.localStorage.setItem(stateKey, JSON.stringify(params));
  window.localStorage.removeItem(stateKey);
};
