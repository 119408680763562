import React from 'react';

import { Loader } from './components';
import { Routes } from './Routes';

const App = () => (
  <>
    <Loader />
    <Routes />
  </>
);

export default App;
