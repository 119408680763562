import cx from 'classnames';
import React from 'react';

import { useIsWaitingForAuth } from '../../hooks/useIsWaitingForAuth';
import { authParams } from '../../utils/params';
import { Centra, CentraShort, Smile } from '../Logos';

import styles from './Container.module.css';

const Illustrations = () => (
  <>
    <div className={cx(styles.animations, styles.bigCircle)} />
    <div className={cx(styles.animations, styles.circle)} />
    <div className={cx(styles.animations, styles.smile)}>
      <Smile />
    </div>
  </>
);

interface Props {
  children: React.ReactNode;
}

const QA_PREFIX = '-qa';
const IS_QA_CLIENT = authParams.client_id?.endsWith(QA_PREFIX);

const containerClass = cx(styles.container, {
  [styles.qa]: import.meta.env.CENTRA_INTERNAL === 'true' || IS_QA_CLIENT,
  [styles.dev]: import.meta.env.DEV,
});

export const Container = ({ children }: Props) => {
  const isWaitingForAuth = useIsWaitingForAuth();

  return (
    <div className={containerClass}>
      <div className={styles.centraLogo}>
        <Centra width={98} />
      </div>
      <Illustrations />
      <div className={styles.centerContainer}>
        <div className={styles.contentWrapper}>
          {!isWaitingForAuth && (
            <div className={styles.centraShortLogo}><CentraShort width={34} /></div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
