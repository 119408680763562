/* eslint-disable max-len */
import React from 'react';

interface Props {
  width?: number;
}

const DEFAULT_WIDTH = 59;

export const Centra = ({ width = DEFAULT_WIDTH }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 76 25">
    <path fill="#fff" d="M2.583 24.001a2.585 2.585 0 0 0 2.584-2.586 2.585 2.585 0 0 0-2.584-2.587A2.585 2.585 0 0 0 0 21.415 2.585 2.585 0 0 0 2.583 24ZM11.13 16.912c1.714 0 3.031-1.144 3.503-2.288l2.236 1.443c-1.143 2.014-3.18 3.432-5.888 3.432-3.602 0-6.881-2.86-6.881-7.039 0-4.153 3.279-7.038 6.881-7.038 2.733 0 4.745 1.442 5.863 3.457l-2.236 1.442c-.447-1.144-1.739-2.288-3.478-2.288-2.733 0-4.223 2.015-4.223 4.452 0 2.438 1.49 4.427 4.223 4.427Zm9.018-3.73c0 2.014 1.441 3.73 3.876 3.73 1.714 0 2.906-.87 3.329-2.014l2.41 1.144c-1.143 2.014-3.155 3.457-5.888 3.457-3.578 0-6.608-2.86-6.608-7.039 0-4.153 2.856-7.038 6.608-7.038 3.453 0 6.31 2.736 6.31 6.889l-.298.87h-9.739Zm.15-2.512h6.88c-.148-1.493-1.59-2.637-3.303-2.637-2.012 0-3.155 1.144-3.578 2.637Zm11.477-4.95h2.584l.298 1.144.149.15.149-.15c.571-.87 2.012-1.442 3.155-1.442 3.453 0 5.44 2.288 5.44 5.446V19.2h-2.856v-8.307c-.075-1.84-.97-2.86-2.857-2.86-1.69 0-3.106 1.368-3.156 3.035v8.157h-2.856V5.72h-.05Zm12.471 0h2.609V2.86L49.115 0h.547v5.745h2.907v2.562h-2.907v6.889c0 .87.596 1.443 1.466 1.443l1.714-.299v2.587l-1.59.298c-2.857 0-4.422-1.442-4.422-4.029v-6.89H44.22V5.72h.025Zm10.335 0h2.584l.298 1.443.149.149.149-.15c.87-1.143 2.012-1.715 3.155-1.715.571 0 .87 0 1.44.298v2.562h-1.713c-1.69 0-3.106 1.368-3.156 3.034v7.86h-2.856V5.72h-.05Zm21.216 13.505h-2.584l-.298-1.144-.149-.15-.149.15c-.87.87-2.286 1.443-3.727 1.443-3.726 0-6.881-2.86-6.881-7.039 0-4.153 3.155-7.038 6.882-7.038 1.44 0 2.856.572 3.726 1.442l.15.15.148-.15.298-1.144h2.584v13.48ZM69.064 8.033c-2.732 0-4.198 2.015-4.198 4.452 0 2.438 1.466 4.452 4.198 4.452 2.435 0 4.15-2.015 4.15-4.452 0-2.462-1.715-4.452-4.15-4.452Z" />
  </svg>
);
