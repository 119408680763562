import React from 'react';

import { externalSignIn } from '../../api/api';
import { useAuth } from '../../hooks/useAuth';
import { AuthFailureResponse, AuthSuccessResponse } from '../../hooks/useAuth/types';
import { pushLocation } from '../../utils/pushLocation';
import { Button } from '../Button/Button';
import { MicrosoftLogo } from '../Logos';

const MICROSOFT_AUTH_CONFIG = {
  clientId: import.meta.env.CENTRA_MICROSOFT_CLIENT_ID,
  extraParams: {
    'prompt': 'select_account',
    'response_mode': 'query',
  },
  height: 600,
  provider: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  scope: ['openid', 'profile', 'email'],
  width: 483,
};

interface Props {
  setErrors: (errors: string[]) => void;
}

export const MicrosoftButton = ({ setErrors }: Props) => {
  const onSuccess = async ({ code }: AuthSuccessResponse) => {
    try {
      const data = await externalSignIn({ code, provider: 'microsoft' });
      if ('errors' in data) {
        setErrors(data.errors);

        return;
      }

      pushLocation(data.redirect_uri);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      setErrors([error.error_description ?? 'Something went wrong.']);
    }
  };

  const onFailure = (errors: AuthFailureResponse) => {
    setErrors(['Something went wrong.']);
    console.error(errors); // eslint-disable-line no-console
  };

  const login = useAuth({ config: MICROSOFT_AUTH_CONFIG, onFailure, onSuccess });

  return (
    <Button onClick={login}>
      <MicrosoftLogo width={18} /> Microsoft
    </Button>
  );
};
