import cx from 'classnames';
import React from 'react';

import styles from './Form.module.css';

export const Form = ({ children, className, noValidate = true, ...formProps }: React.FormHTMLAttributes<HTMLFormElement>) => {
  return (
    <form className={cx(styles.form, className)} {...formProps} noValidate={noValidate}>
      {children}
    </form>
  );
};
