import React from 'react';

import { checkResetPasswordHash, resetPassword } from '../../api/api';
import {
  Button,
  Errors,
  Form,
  Input,
  Title,
} from '../../components';
import { PasswordRules } from '../../components/PasswordRules/PasswordRules';
import { isDefined } from '../../utils/isDefined';
import { passwordSchema } from '../../utils/passwordSchema';
import { PATHS, routeMatcher } from '../../utils/routes';
import { MFAVerify } from '../MFAVerify/MFAVerify';

interface Props {
  hash: string;
}

const [isResetPasswordPage, params] = routeMatcher(PATHS.RESET_PASSWORD, location.pathname);

if (isResetPasswordPage && isDefined(params.hash)) {
  checkResetPasswordHash(params.hash);
}

export const SetNewPassword = ({ hash }: Props) => {
  const [password, setPassword] = React.useState('');
  const [repeatPassword, setRepeatPassword] = React.useState('');
  const [errors, setErrors] = React.useState<string[]>([]);
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const [needsMFACode, setNeedsMFACode] = React.useState(false);
  const formId = React.useId();

  const handlePassChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(evt.target.value);
    setErrors([]);
  }, []);

  const handleRepeatPassChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(evt.target.value);
    setErrors([]);
  }, []);

  const resetPass = React.useCallback(async (code?: string) => {
    const validation = passwordSchema.validate({ password, repeatPassword });
    if (!validation.isSuccess) {
      setErrors(validation.messages);

      return;
    }

    const response = await resetPassword({ code, hash, password, repeatPassword });

    if (!isDefined(response)) {
      setIsSuccessful(true);

      return;
    }

    if ('errors' in response) {
      setErrors(response.errors);

      return;
    }

    if (response.needsMFACode) {
      setNeedsMFACode(true);
    }
  }, [password, repeatPassword, hash]);

  const handleSubmit = React.useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    resetPass();
  }, [resetPass]);

  if (isSuccessful) {
    return (
      <>
        <Title>Reset password completed</Title>
        <p>Thank you! Your password has been successfully reset. Now you can log in using your new password.</p>
      </>
    );
  }

  if (needsMFACode) {
    return <MFAVerify title="Confirm setting your new password" onConfirm={resetPass} errors={errors} setErrors={setErrors} />;
  }

  return (
    <>
      <Title>Set your new password</Title>
      <Form id={formId} onSubmit={handleSubmit}>
        <Input
          type="password"
          label="New password"
          placeholder="New password"
          onChange={handlePassChange}
          value={password}
          required
        />
        <Input
          type="password"
          label="Confirm new password"
          placeholder="Confirm new password"
          onChange={handleRepeatPassChange}
          value={repeatPassword}
          required
        />
        <Errors messages={errors} />
        <PasswordRules />
      </Form>
      <Button type="submit" form={formId} accent>Set your new password</Button>
    </>
  );
};
