import { render } from 'preact/compat';
import React from 'react';

import { dispatchAuthSuccess, getIsWaitingForAuth } from './hooks/useAuth';
import { getQueryParams } from './utils/getQueryParamsFromQueryString';
import App from './App';

import './css/global.css';

if (getIsWaitingForAuth(location.search)) {
  dispatchAuthSuccess(getQueryParams(window.location.search));
}

render(<App />, document.getElementById('root') as HTMLElement);
