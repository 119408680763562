import React from 'react';

import { BackToLogin, Title } from '../../components';

export const CreateAccount = () => {
  return (
    <>
      <Title>Create an account</Title>
      <p>In order to create an account connected to your email, please reach out to your point of contact with Centra.</p>
      <BackToLogin />
    </>
  );
};
