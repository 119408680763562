import { nanoid } from 'nanoid';
import React from 'react';

import { isDefined } from '../../utils/isDefined';
import { useEvent } from '../useEvent';

import { AuthParams } from './types';
import { createPopup, getAuthHref, getStateKey, STATE_PREFIX } from './utils';

const POPUP_WATCH_INTERVAL_MS = 255;

export const useAuth = ({ config, onSuccess, onFailure }: AuthParams) => {
  const popupRef = React.useRef<Window | null>(null);
  const intervalRef = React.useRef<number>();
  const stateRef = React.useRef<string>();

  const onStorageChange = useEvent((evt: StorageEvent) => {
    if (!isDefined(stateRef.current) || evt.key !== getStateKey(stateRef.current) || !isDefined(evt.newValue)) {
      return;
    }

    cleanUp();
    const params = JSON.parse(evt.newValue);
    const { code } = params;
    isDefined(code)
      ? onSuccess({ code, params })
      : onFailure(params);
  });

  const onPopupClosed = useEvent(() => {
    cleanUp();
    onFailure({ error: 'Popup was closed.' });
  });

  const onPopupFailure = useEvent(() => onFailure({ error: 'Could not open popup.' }));

  const cleanUp = React.useCallback(() => {
    window.clearInterval(intervalRef.current);
    window.removeEventListener('storage', onStorageChange);
    popupRef.current?.close();
    popupRef.current = null;
    intervalRef.current = undefined;
    stateRef.current = undefined;
  }, [onStorageChange]);

  React.useEffect(() => {
    return () => cleanUp();
  }, [cleanUp]);

  const { provider, clientId, redirectUri, scope, extraParams = {}, height, title, width } = config;

  return React.useCallback(() => {
    if (isDefined(popupRef.current)) {
      popupRef.current.focus();

      return;
    }

    stateRef.current = `${STATE_PREFIX}${nanoid()}`;
    const href = getAuthHref({ clientId, extraParams, provider, redirectUri, scope, state: stateRef.current });
    popupRef.current = createPopup({ height, title, url: href, width });

    if (!isDefined(popupRef.current)) {
      onPopupFailure();

      return;
    }

    window.addEventListener('storage', onStorageChange);

    intervalRef.current = window.setInterval(() => {
      if (popupRef.current?.closed) {
        onPopupClosed();
      }
    }, POPUP_WATCH_INTERVAL_MS);
  }, [clientId, extraParams, height, onPopupClosed, onPopupFailure, onStorageChange, provider, redirectUri, scope, title, width]);
};
