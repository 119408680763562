/* eslint-disable max-len */
import React from 'react';

interface Props {
  width?: number;
  isOpen?: boolean;
}

const DEFAULT_WIDTH = 22;

export const Eye = ({ width = DEFAULT_WIDTH, isOpen = true }: Props) => (
  <svg width={width} height={width} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    {
      isOpen
        ? <path d="M17.6569 10.1454C18.1148 10.6288 18.1148 11.382 17.6569 11.8541C16.2162 13.338 13.614 16.2495 11.0007 16.2495C8.46547 16.2495 5.7851 13.3492 4.3444 11.8541C3.8865 11.3707 3.8865 10.6175 4.3444 10.1454C5.7851 8.66154 8.23094 5.75 11.0007 5.75C13.6475 5.75 16.2274 8.66154 17.6569 10.1454ZM11.0007 8.65029C9.71631 8.65029 8.6665 9.70699 8.6665 10.9998C8.6665 12.2925 9.71631 13.3492 11.0007 13.3492C12.285 13.3492 13.3348 12.2925 13.3348 10.9998C13.3348 9.70699 12.2962 8.65029 11.0007 8.65029Z" fill="#4F4F4F" />
        : <path fillRule="evenodd" clipRule="evenodd" d="M17.6569 10.1454C18.1148 10.6288 18.1148 11.382 17.6569 11.8541C17.5565 11.9575 17.4505 12.0678 17.3393 12.1836C15.8536 13.7295 13.4319 16.2495 11.0007 16.2495C8.56708 16.2495 5.99971 13.577 4.52351 12.0404L4.3444 11.8541C3.8865 11.3707 3.8865 10.6175 4.3444 10.1454C4.49027 9.99517 4.64644 9.83029 4.81222 9.65528C6.2837 8.1018 8.51137 5.75 11.0007 5.75C13.4647 5.75 15.8707 8.27325 17.3446 9.81895C17.4539 9.93361 17.5582 10.0429 17.6569 10.1454ZM7.5 11.5H14.5V10.5H7.5V11.5Z" fill="#636363" />
    }
  </svg>
);
