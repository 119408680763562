import React from 'react';
import { useLocation } from 'wouter';

import { MFAFinalizeResponse } from '../../../typings/types';
import { finalizeMFA, initializeMFA } from '../../api/api';
import { ActionContainer, Button, Errors, Form, Input, Link, Title } from '../../components';
import { isDefined } from '../../utils/isDefined';
import { isEmpty } from '../../utils/isEmpty';
import { PATHS } from '../../utils/routes';

import styles from './MFASetup.module.css';

interface Props {
  onEnable: (payload: MFAFinalizeResponse['data']) => void;
  accessToken: string | undefined;
  skipUrl?: string;
}

export const EnableMFA = ({ onEnable, accessToken, skipUrl }: Props) => {
  const [code, setCode] = React.useState('');
  const [shouldShowSecret, setShouldShowSecret] = React.useState(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const toggleSecret = React.useCallback(() => setShouldShowSecret(prev => !prev), []);
  const formId = React.useId();
  const [secret, setSecret] = React.useState('');
  const [qrImage, setQRImage] = React.useState('');
  const [, navigate] = useLocation();

  React.useEffect(() => {
    initializeMFA(accessToken).then(data => {
      if ('errors' in data) {
        return navigate(PATHS.FAILED);
      }

      setQRImage(data.qr_code);
      setSecret(data.secret);
    });
  }, [navigate, accessToken]);

  const handleCodeChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setCode(evt.target.value);
    setErrors([]);
  }, []);

  const handleEnable = React.useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isEmpty(code)) {
      setErrors(['Verification code is required.']);

      return;
    }

    finalizeMFA({ code }, accessToken).then(data => {
      if ('errors' in data) {
        setErrors(data.errors);

        return;
      }
      onEnable(data);
    });
  }, [code, onEnable, accessToken]);

  return (
    <>
      <Title>Enable Multi-Factor Authentication</Title>
      <Form id={formId} onSubmit={handleEnable}>
        <p>
          <span className={styles.info}>
            After following steps from <Link variant="info" external to="#">this article</Link>,
            scan the QR code in your authenticator app or enter the code {' '}
            <button type="button" className={styles.toggleButton} onClick={toggleSecret}>
              {
                shouldShowSecret
                  ? 'Show QR code'
                  : 'Show code instead'
              }
            </button>
          </span>
          {shouldShowSecret
            ? <span className={styles.secretContainer}>{secret}</span>
            : <span className={styles.qrCode} style={{ backgroundImage: `url('${qrImage}')` }} />
          }

        </p>
        <Input
          label="Enter verification code"
          placeholder="Verification code"
          description="After scanning the QR code, your app will display a verification code."
          onChange={handleCodeChange}
          value={code}
          required
        />
        <Errors messages={errors} />
      </Form>
      <div className={styles.enableMfaActions}>
        <ActionContainer>
          <Button type="submit" form={formId} accent>Enable</Button>
          {isDefined(skipUrl) && <Link variant="secondaryAction" external to={skipUrl}>Skip for now</Link>}
        </ActionContainer>
      </div>
    </>
  );
};
