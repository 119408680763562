import React from 'react';

import { postForgotPassword } from '../../api/api';
import {
  ActionContainer,
  BackToLogin,
  Button,
  Errors,
  Form,
  Input,
  Title,
} from '../../components/';
import { isDefined } from '../../utils/isDefined';
import { createSchema, isEmail, required } from '../../utils/validation';

const formSchema = createSchema({
  email: [
    required('Email is required.'),
    isEmail('Email must be valid.'),
  ],
});

export const ForgotPassword = () => {
  const [email, setEmail] = React.useState('');
  const [errors, setErrors] = React.useState<string[]>([]);
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const formId = React.useId();

  const handleEmailChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
    setErrors([]);
  }, []);

  const handleSubmit = React.useCallback(async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const validation = formSchema.validate({ email });
    if (!validation.isSuccess) {
      setErrors(validation.messages);

      return;
    }

    const response = await postForgotPassword(email);
    setIsSuccessful(!isDefined(response?.errors));
    setErrors(response?.errors ?? []);
  }, [email]);

  if (isSuccessful) {
    return (
      <>
        <Title>A link was sent to your email</Title>
        <p>Click the link to set up your new password.</p>
        <BackToLogin />
      </>
    );
  }

  return (
    <>
      <Title>Reset your password</Title>
      <Form id={formId} onSubmit={handleSubmit}>
        <Input
          type="email"
          label="Email"
          placeholder="name@example.com"
          onChange={handleEmailChange}
          value={email}
          required
        />
        <Errors messages={errors} />
      </Form>
      <ActionContainer>
        <Button type="submit" form={formId} accent>Reset your password</Button>
        <BackToLogin />
      </ActionContainer>
    </>
  );
};
