/* eslint-disable max-len */
import React from 'react';

interface Props {
  width?: number;
}

const DEFAULT_WIDTH = 24;

export const MicrosoftLogo = ({ width = DEFAULT_WIDTH }: Props) => (
  <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3983 11.4039H0V0.00561523H11.3983V11.4039Z" fill="#F1511B" />
    <path d="M23.9833 11.4039H12.5852V0.00561523H23.9833V11.4039Z" fill="#80CC28" />
    <path d="M11.3979 23.9934H0V12.5952H11.3979V23.9934Z" fill="#00ADEF" />
    <path d="M23.9833 23.9934H12.5852V12.5952H23.9833V23.9934Z" fill="#FBBC09" />
  </svg>
);
